import React, { useEffect, useMemo, useRef, useState } from "react"
import { cateringOrder, getAdminLocation, getAllCompanyNameQuery, getCateringProduct, getDepartment, searchColleague } from "../../../services/ApiServices"
import { changeAllLocationValue } from "../../../store/slices/APIResponseSlice"
import { useDispatch, useSelector } from "react-redux"
import { InputMask } from '@react-input/mask'
import { handleError } from "../../../const"
import { Tooltip } from "react-tooltip"
import { toast } from "react-hot-toast"
import CreatableSelect from "react-select/creatable"
import ReactDatePicker from 'react-datepicker'
import Loader from "../../Loader/Loader"
import ReactSelect from "react-select"
import Select from "react-select"
import moment from "moment/moment"
import "react-tooltip/dist/react-tooltip.css"
import "./WithoutMeeting.scss"

// ==== Imported Image ====
import iconAdd from "../../../assets/img/icon-add.svg"
import iconDelete from "../../../assets/img/icon-trash.svg"
import iconArrow from "../../../assets/img/icon-arrow-down.svg"

export default function WithoutMeeting() {
    const allCanteenUser = useSelector((state) => state?.APIResponse?.AllCanteenUsers)
    const allLocations = useSelector((state) => state?.APIResponse?.AllLocations)

    const [isLoading, setIsLoading] = useState(false)
    const [isSaveOrderLoading, setIsSaveOrderLoading] = useState(false)
    // const [orderBySearchStatus, setOrderBySearchStatus] = useState(false)
    const [userSearch, setUserSearch] = useState("")
    const [colleaguesList, setColleaguesList] = useState([])
    const [orderByName, setOrderByName] = useState("")
    const [orderByEmail, setOrderByEmail] = useState("")
    const [allCateringProduct, setAllCateringProduct] = useState([])
    const [allDepartments, setAllDepartments] = useState([])
    const [allCompanyResponse, setAllCompanyResponse] = useState([])

    const [orderDetails, setOrderDetails] = useState([])

    const [inputLocation, setInputLocation] = useState({ value: 0, label: "Select Location" })
    const [inputDate, setInputDate] = useState(moment().format("YYYY-MM-DD"))
    const [inputDepartment, setInputDepartment] = useState({ value: 0, label: "Select Costcenter" })
    const [inputCanteenUser, setInputCanteenUser] = useState([])
    const [inputDeliveryMethod, setInputDeliveryMethod] = useState("Pickup")
    const [inputPickupTime, setInputPickupTime] = useState(moment().add(30, "m").format("HH:mm"))
    const [inputSharedComment, setInputSharedComment] = useState("")
    const [inputInternalComment, setInputInternalComment] = useState("")
    const [companyQueryString, setCompanyQueryString] = useState("")
    const [inputDiscount, setInputDiscount] = useState("")
    const [inputAmount, setInputAmount] = useState("")
    const [disPercent, setDisPercent] = useState("")
    const [discountTotalDisplay, setDiscountTotalDisplay] = useState(false)
    const [discountAmountDisplay, setDiscountAmountDisplay] = useState(false)

    const [showValidationMessage, setShowValidationMessage] = useState(false)

    const [error, setError] = useState({ Status: false, Indexs: [] })

    const locationRef = useRef()
    const costCenterRef = useRef()
    const wrapperRef = useRef(null)

    // const orderBySearch = useRef()

    const dispatch = useDispatch()

    // Company Query Search
    useEffect(() => {
        let subscribed = true

        const timeout = setTimeout(() => {
            if (companyQueryString?.trim()) {
                getAllCompanyNameQuery(companyQueryString).then((response) => {
                    if (subscribed) {
                        if (typeof response === "object" && response?.length > 0) {
                            setAllCompanyResponse(response)
                        } else {
                            handleError(response)
                        }
                    }
                }).catch((error) => {
                    if (subscribed) {
                        handleError(error)
                    }
                }).finally(() => {
                    if (subscribed) {
                    }
                })
            }
        }, 1000)

        return () => {
            subscribed = false
            clearTimeout(timeout)
        }
    }, [companyQueryString])

    const meetingLocation = useMemo(() => {
        if (allLocations?.length > 0 && inputLocation?.value) {
            return allLocations?.find((d) => d?.Id === inputLocation?.value)
        } else {
            return {}
        }
    }, [allLocations, inputLocation?.value])

    // Total Price
    const calPrice = useMemo(() => {
        let price = 0
        let filteredData = orderDetails?.filter((d) => d?.ProductName && d?.NumberOfItems && d?.NumberOfItems && d?.Price)
        if (filteredData?.length > 0) {
            filteredData?.forEach((d) => {
                price += d?.NumberOfItems * d?.Price
            })
        }
        return price
    }, [orderDetails])

    // Discounted Price
    const discountTotal = useMemo(() => {
        let discountAmount = calPrice * disPercent / 100
        let finalAmount = calPrice - discountAmount

        return { amount: finalAmount.toFixed(2), discount: discountAmount === 0 ? "" : (discountAmount)?.toFixed(2) }
    }, [calPrice, disPercent])

    // Discounted Amount
    const discountAmount = useMemo(() => {
        let discountTotal = calPrice - inputAmount
        let percent = Math.round(inputAmount * 100 / calPrice)
        setInputDiscount(
            percent === 0
                ? ""
                : percent
        )

        return { amount: discountTotal?.toFixed(2) }
    }, [calPrice, inputAmount])

    useEffect(() => {
        setInputAmount(discountTotal.discount === 0 ? "" : discountTotal?.discount)
    }, [discountTotal])

    const colleagueOptions = colleaguesList?.map((user) => ({
        label: `${user.Name} (${user.Email})`,
        value: user
    }))

    const handleChange = (selectedOption) => {
        if (selectedOption) {
            const user = selectedOption.value
            setOrderByName(user?.Name)
            setOrderByEmail(user?.Email)
            setUserSearch("")
            setShowValidationMessage(false)
            // setColleaguesList([])
            // setOrderBySearchStatus(false)
        } else {
            setOrderByName("")
            setOrderByEmail("")
        }
    }

    const formatOptionLabel = ({ value }) => (
        <div className="item-card item-card-hover">
            <div>
                <b className="txt-name mb-0">{value?.Name}</b>
            </div>
            <div>
                <p className="txt-sm mb-0">{value?.Email}</p>
            </div>
        </div>
    )

    const handleWrapperClick = () => {
        if (!showValidationMessage && userSearch?.length < 3) {
            setShowValidationMessage(true)
        }
    }

    const handleInputChange = (inputValue) => {
        setUserSearch(inputValue)
        // setShowValidationMessage(false)
    }

    const addRow = () => {
        setOrderDetails([
            ...orderDetails,
            {
                Id: 0,
                ProductName: "",
                NumberOfItems: "",
                Price: "",
                DeliveryHour: moment(inputPickupTime, "HH:mm").format("HH"),
                DeliveryMinute: moment(inputPickupTime, "HH:mm").format("mm")
            }
        ])
    }

    const removeRow = (order) => {
        setOrderDetails((prev) => prev?.filter((d) => d !== order))
    }

    const handleProductChange = (Id, i, event) => {
        let arr = [...orderDetails]
        let product = allCateringProduct?.length > 0
            ? allCateringProduct?.find((product) => product?.Id === Id)
            : {}
        if (event?.value && event?.label && event?.value === event?.label) {
            // Other Product Case
            arr[i].ProductName = event?.value
            arr[i].Id = event?.value
            setOrderDetails(arr)
        } else {
            arr[i].ProductName = product?.Name ?? ""
            arr[i].Id = product?.Id ?? 0
            setOrderDetails(arr)
        }
        updateOrderPrice()
    }

    const handleQtyChange = (qty, i) => {
        let arr = [...orderDetails]
        arr[i].NumberOfItems = qty
        setOrderDetails(arr)
    }

    // ===============================================================================================
    const handleDeliveryTimeChange = (time, index) => {
        let arr = [...orderDetails]

        setError((prev) => ({ ...prev, Indexs: prev?.Indexs?.filter((i) => i !== index) }))

        // /^(?:[01]\d|2[0-3]):[0-5]\d$/
        const checkLen0 = () => {
            if (time?.length === 0) {
                arr = arr?.map((p, i) => ({
                    ...p,
                    DeliveryHour: i === index ? "" : p?.DeliveryHour,
                    DeliveryMinute: i === index ? "" : p?.DeliveryMinute
                }))
            }
        }
        const checkLen1 = () => {
            if (time?.length === 1) {
                if ((/^[0-2]$/).test(time)) {
                    // position 1
                    arr = arr?.map((p, i) => ({
                        ...p,
                        DeliveryHour: i === index ? time?.slice(0, 1) : p?.DeliveryHour,
                        DeliveryMinute: i === index ? "" : p?.DeliveryMinute
                    }))
                } else {
                    checkLen0()
                }
            }
        }
        const checkLen2 = () => {
            if (time?.length === 2) {
                if ((/^(?:[01]\d|2[0-3])$/).test(time)) {
                    // position 2
                    arr = arr?.map((p, i) => ({
                        ...p,
                        DeliveryHour: i === index ? time?.slice(0, 2) : p?.DeliveryHour,
                        DeliveryMinute: i === index ? "" : p?.DeliveryMinute
                    }))
                } else {
                    checkLen1()
                }
            }
        }
        const checkLen4 = () => {
            if (time?.length === 4) {
                if ((/^(?:[01]\d|2[0-3]):[0-5]$/).test(time)) {
                    // position 4
                    arr = arr?.map((p, i) => ({
                        ...p,
                        DeliveryHour: i === index ? time?.slice(0, 2) : p?.DeliveryHour,
                        DeliveryMinute: i === index ? time?.slice(3, 4) : p?.DeliveryMinute
                    }))
                } else {
                    checkLen2()
                }
            }
        }
        const checkLen5 = () => {
            if (time?.length === 5) {
                if ((/^(?:[01]\d|2[0-3]):[0-5]\d$/).test(time)) {
                    // position 5
                    arr = arr?.map((p, i) => ({
                        ...p,
                        DeliveryHour: i === index ? time?.slice(0, 2) : p?.DeliveryHour,
                        DeliveryMinute: i === index ? time?.slice(3, 5) : p?.DeliveryMinute
                    }))
                } else {
                    checkLen4()
                }
            }
        }

        checkLen0()
        checkLen1()
        checkLen2()
        checkLen4()
        checkLen5()

        setOrderDetails(arr)
    }

    const handleOnBlur = (time, index) => {
        if (time?.length === 1) {
            handleDeliveryTimeChange("0" + time + ":00", index)
        }
        if (time?.length === 2) {
            handleDeliveryTimeChange(time + ":00", index)
        }
        if (time?.length === 4) {
            handleDeliveryTimeChange(time?.slice(0, 2) + ":0" + time?.slice(3, 4), index)
        }
    }
    //===============================================================================================

    const handlePriceChange = (price, i) => {
        let arr = [...orderDetails]
        arr[i].Price = price
        setOrderDetails(arr)
    }

    const updateOrderPrice = () => {
        setOrderDetails((prev) => prev?.map((d) => ({
            ...d,
            Price: !d?.ProductName
                ? ""
                : allCateringProduct?.filter((data) => data?.Name?.trim() === d?.ProductName?.trim())?.length
                    ? allCateringProduct?.find((data) => data?.Name?.trim() === d?.ProductName?.trim())?.Price
                    : d?.Price
        })))
    }

    const GetDepartments = async () => {
        setIsLoading(true)
        await getDepartment().then((response) => {
            if (response && typeof response === "object" && response?.length > 0) {
                setAllDepartments(response?.filter((department) => department?.Active))
                const defaultDepartment = response?.filter((department) => department?.Active)?.find((department) => department?.IsDefault)
                setInputDepartment({
                    value: 0,
                    label: "Select Costcenter"
                })
                // setInputDepartment({
                //     value: defaultDepartment?.Id ?? 0,
                //     label: defaultDepartment?.Name ?? "Select Costcenter"
                // })
            } else {
                setAllDepartments([])
            }
        }).catch((error) => {
            setAllDepartments([])
            handleError((error))
        }).finally(() => {
            setIsLoading(false)
        })
    }

    const CreateUpdateOrder = async () => {
        const data = {
            "DepartmentId": inputDepartment?.value,
            "Responsible": inputCanteenUser?.length > 0 ? inputCanteenUser : [0],
            // "NumberOfPeople": 0,
            // "ExternalAttendeesPeoples": 0,
            "Discount": inputDiscount === "" ? 0 : inputDiscount,
            "Products": orderDetails?.length > 0
                ? orderDetails?.filter((d) => allCateringProduct?.filter((data) => data?.Name === d?.ProductName)?.length > 0 && d?.NumberOfItems && d?.NumberOfItems > 0)?.map((d) => ({
                    ProductId: allCateringProduct?.find((data) => data?.Name === d?.ProductName)?.Id,
                    NumberOfItems: d?.NumberOfItems,
                    DeliveryHour: 0,
                    DeliveryMinute: 0
                }))
                : [],
            "ProductsOther": orderDetails?.length > 0
                ? orderDetails?.filter((d) => d?.ProductName?.trim() && !allCateringProduct?.some((data) => data?.Name === d?.ProductName) && d?.NumberOfItems)?.map((d) => ({
                    Id: 0,
                    Name: d?.ProductName,
                    NumberOfItems: d?.NumberOfItems,
                    Price: d?.Price,
                    DeliveryHour: 0,
                    DeliveryMinute: 0
                }))
                : [],
            "Comment": inputSharedComment,
            "InternalComment": inputInternalComment,
            "DeliveryMethod": inputDeliveryMethod,
            "OrderedByName": orderByName,
            "OrderedByEmail": orderByEmail,
            "DeliveryDate": `${moment(inputDate).format("YYYY-MM-DDT00:00:00")}.000Z`,
            "DeliveryHour": Number(moment(inputPickupTime, "HH:mm").format("HH")),
            "DeliveryMinute": Number(moment(inputPickupTime, "HH:mm").format("mm")),
            "LocationId": inputLocation?.value,
        }

        // if ((data?.Products?.length <= 0 || data?.Products[0]?.NumberOfItems < 0 === undefined) && (data?.ProductsOther?.length <= 0 || data?.ProductsOther[0]?.NumberOfItems < 0 === undefined)) {
        if (orderDetails?.length < 0 || !orderDetails[0]?.NumberOfItems >= 1 || orderDetails[0]?.ProductName === "") {
            toast.error("Must have one product!")
        } else if (!data?.DepartmentId) {
            toast.error("Costcenter is required!")
        } else if (!data?.OrderedByName && !data?.OrderedByEmail) {
            toast.error("Order By is required!")
        } else {
            setIsSaveOrderLoading(true)
            await cateringOrder(data).then((response) => {
                if (!response) {
                    toast.success("Order created successfully")

                    setInputLocation(0)
                    setInputDate(moment().format("YYYY-MM-DD"))
                    setOrderDetails([])
                    setOrderByEmail("")
                    setOrderByName("")
                    setInputPickupTime(moment().add(30, "m").toDate())
                    setInputSharedComment("")
                    setInputInternalComment("")
                    setInputDepartment({ value: 0, label: "Select Costcenter" })
                } else {
                    handleError(response)
                }
            }).catch((error) => {
                handleError(error)
            }).finally(() => {
                setIsSaveOrderLoading(false)
            })
        }
    }

    const GetAdminLocation = async () => {
        await getAdminLocation().then((response) => {
            if (typeof response === "object" && response?.length > 0) {
                dispatch(changeAllLocationValue(response))
            } else {
                handleError(response)
            }
        }).catch((error) => {
            dispatch(changeAllLocationValue([]))
            handleError(error)
        })
    }

    const handleClickOutside = (event) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            setShowValidationMessage(false)
        }
    }

    useEffect(() => {
        if (userSearch?.length >= 3) {
            searchColleague(userSearch).then((res) => {
                setColleaguesList(res)
            })
        } else {
            setColleaguesList([])
        }
    }, [userSearch])

    // GET CATERING PRODUCTS
    useEffect(() => {
        let subscribed = true

        if (inputPickupTime && inputLocation) {
            const payloadData = {
                locationId: inputLocation?.value,
                date: inputDate,
                startTimeMeetingHour: moment(inputPickupTime, "HH:mm").format("HH"),
                startTimeMeetingMinute: moment(inputPickupTime, "HH:mm").format("mm"),
                endTimeMeetingHour: moment(inputPickupTime, "HH:mm").format("HH"),
                endTimeMeetingMinute: moment(inputPickupTime, "HH:mm").format("mm"),
            }

            getCateringProduct(payloadData).then((response) => {
                if (subscribed) {
                    let arr = []
                    response?.length > 0 && response?.forEach((d) => {
                        d?.Products?.length > 0 && arr?.push(...d?.Products)
                    })
                    setAllCateringProduct(arr)
                }
            }).catch((error) => {
                if (subscribed) {
                    handleError(error)
                }
            })
        } else {
            if (subscribed) {
                setAllCateringProduct([])
            }
        }

        return () => { subscribed = false }
    }, [inputPickupTime, inputLocation?.value, inputDate, inputLocation])

    useEffect(() => {
        GetDepartments()
        GetAdminLocation()
    }, [])

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])

    return (
        <>
            {(isLoading || isSaveOrderLoading) && <Loader />}

            {/* ========================== Input Section ========================== */}
            {/* ========================== Input Section ========================== */}
            <section className="section mb-4">
                {/* Input Location/Date */}
                <div className="row">
                    {/* Input Location */}
                    <div className="col-6">
                        <div className="inputSec-icon mb-3">
                            <Select
                                ref={locationRef} openMenuOnFocus={true}
                                value={[inputLocation]}
                                onChange={(e) => setInputLocation(e)}
                                options={allLocations?.length > 0
                                    ? [{ value: 0, label: "Select Location" }, ...allLocations?.map((d) => ({ value: d?.Id, label: d?.Name }))]
                                    : [{ value: 0, label: "Select Location" }]
                                }
                                className="form-control p-0" placeholder="Select Location"
                                styles={{
                                    control: (baseStyles) => ({
                                        ...baseStyles,
                                        border: "none",
                                        boxShadow: "none",
                                        padding: "8px 10px",
                                        borderRadius: 8,
                                    }),
                                }}
                                components={{
                                    IndicatorSeparator: () => null,
                                    DropdownIndicator: () => null
                                }}
                            />
                            <span onClick={() => locationRef.current?.focus()} className="custom-drop-down-span">
                                <img className="custom-drop-down-image" alt="icon-user" src={iconArrow} />
                            </span>
                        </div>
                    </div>

                    {/* Input Date */}
                    <div className="col-6">
                        <div className="inputSec-icon">
                            <ReactDatePicker
                                selected={moment(inputDate)._d}
                                onChange={(date) => setInputDate(moment(date).format("YYYY-MM-DD"))}
                                className="form-control"
                                dateFormat="dd.MM.yyyy"
                                calendarStartDay={1}
                            />
                        </div>
                    </div>
                </div>
            </section>

            {/* ========================== Edit Order Section ========================== */}
            {/* ========================== Edit Order Section ========================== */}
            {inputLocation?.value > 0 && (
                <>
                    <div className="card card-revert mt-3 cardhide">
                        <div className="row mb-3 mt-2" id="prodList">
                            <div className="col-9">
                                <div className="pagetitle mt-2"><h2>Orders</h2></div>
                            </div>
                        </div>

                        {/*================= Orders Table =================*/}
                        <div className="table-responsive table-custom edit" >
                            <table className="table table-hover valignM">
                                <tbody>
                                    {orderDetails?.length > 0 ? (orderDetails?.map((order, i) =>
                                        <tr key={i}>
                                            {/* Input Product */}
                                            <td className="position-relative">
                                                <CreatableSelect
                                                    isClearable
                                                    placeholder="Select Product"
                                                    value={order?.ProductName && [{ value: order?.Id, label: order?.ProductName }]}
                                                    onChange={(e) => e && handleProductChange(Number(e.value), i, e)}
                                                    options={allCateringProduct?.map((d) => ({ value: d?.Id, label: d?.Name }))}
                                                    components={{
                                                        IndicatorSeparator: () => null,
                                                        DropdownIndicator: () => null,
                                                        ClearIndicator: () => null,
                                                    }}
                                                />
                                                {order?.ProductName && allCateringProduct?.filter((d) => d?.Name?.trim() === order?.ProductName?.trim())?.length > 0 && (
                                                    <>
                                                        <i
                                                            className="fa fa-selectedMeeting-circle position-absolute tool-tip"
                                                            data-tooltip-place="top" data-tooltip-id="my-tooltip"
                                                            data-tooltip-content={allCateringProduct?.find((d) => d?.Name?.trim() === order?.ProductName?.trim())?.Notes} />
                                                        <Tooltip id="my-tooltip" />
                                                    </>
                                                )}
                                            </td>

                                            {/* Input Delivery Time */}
                                            {inputDeliveryMethod === "Delivery" && (
                                                <td className="dlvryTime-td">
                                                    <InputMask
                                                        mask="HH:mm"
                                                        onBlur={(e) => handleOnBlur(e.target.value, i)}
                                                        replacement={{ H: /\d/, m: /\d/ }}
                                                        value={(() => {
                                                            const time = orderDetails?.find((prod, index) => index === i)
                                                            return time?.DeliveryMinute ? time?.DeliveryHour + ":" + time?.DeliveryMinute : time?.DeliveryHour
                                                        })()}
                                                        onChange={(e) => handleDeliveryTimeChange(e.target.value, i)}
                                                        separate
                                                        className="form-control text-center"
                                                        placeholder="HH:mm"
                                                        style={{ border: error?.Status && error?.Indexs?.some((prod) => prod === i) && "1px solid red" }}
                                                    />
                                                </td>
                                            )}

                                            {/* Input Quantity */}
                                            <td className="qty-td">
                                                <input
                                                    value={order?.NumberOfItems}
                                                    onChange={(e) => Number.isInteger(Number(e.target.value)) && handleQtyChange(Number(e.target.value), i)}
                                                    min={0}
                                                    max={100}
                                                    type="number"
                                                    className="form-control text-center"
                                                    placeholder="Qty."
                                                />
                                            </td>

                                            {/* Input Price */}
                                            <td className="price-td">
                                                <input
                                                    type="number"
                                                    value={order?.Price ?? ""}
                                                    onChange={(e) => {
                                                        const value = e.target.value
                                                        if (value === "" || Number(value) >= 0) {
                                                            handlePriceChange(value === "" ? "" : Number(value), i)
                                                        }
                                                    }}
                                                    disabled={allCateringProduct?.some((d) => d?.Name.trim() === order?.ProductName.trim())}
                                                    className="form-control text-center"
                                                    placeholder="Price"
                                                />
                                            </td>

                                            {/* Delete Button */}
                                            <td className="dlt-td">
                                                <button onClick={() => removeRow(order)} type="button" className="btn btn-secondary btn-icon">
                                                    <img alt="icon-trash" src={iconDelete} />
                                                </button>
                                            </td>
                                        </tr>
                                    )) : (
                                        <tr>
                                            <td colSpan="4">
                                                <span className="txt-gray d-block no-order">
                                                    No Orders</span>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>

                            {/*================= Discount & Amount Input =================*/}
                            <div className="discount d-flex justify-content-end">
                                <div className="col-8 p-3 d-flex justify-content-end align-items-center">
                                    <span className="txt500">Discount</span>
                                </div>

                                <div className="col-4 d-flex justify-content-end align-items-center">
                                    {/* Input Discount */}
                                    <div className="input-group">
                                        <input
                                            min={0} max={100}
                                            value={inputDiscount}
                                            onChange={(e) => {
                                                if (e.target.value >= 0 && e.target.value <= 100) {
                                                    setInputDiscount(e.target.value === 0 ? "" : e.target.value)
                                                    setDisPercent(e.target.value === 0 ? "" : e.target.value)
                                                    setDiscountTotalDisplay(true)
                                                    setDiscountAmountDisplay(false)
                                                }
                                            }}
                                            type="number"
                                            className="discount-input form-control form-control-sm text-center"
                                            placeholder="Discount"
                                            style={{ borderRight: "none", boxShadow: "none" }}
                                        />
                                        <span className="input-group-text" style={{ backgroundColor: "white", border: "1px solid #e8ecef" }}>
                                            <i className="fa-solid fa-percent" />
                                        </span>
                                    </div>

                                    {/* Discount Amount */}
                                    <div className="input-group">
                                        <input
                                            type="number"
                                            value={inputAmount}
                                            onChange={(e) => {
                                                if (e.target.value >= 0 && e.target.value <= calPrice) {
                                                    setInputAmount(e.target.value === 0 ? "" : e.target.value)
                                                    setDiscountAmountDisplay(true)
                                                    setDiscountTotalDisplay(false)
                                                }
                                            }}
                                            className="discount-amount form-control form-control-sm text-center"
                                            placeholder="Amount"
                                            style={{ borderRight: "none", boxShadow: "none" }}
                                        />
                                        <span className="input-group-text" style={{ backgroundColor: "white", border: "1px solid #e8ecef" }}>Kr</span>
                                    </div>
                                </div>
                            </div>

                            <div className="d-flex justify-content-end">
                                <div className="col-8 p-3 d-flex justify-content-end align-items-center">
                                    <span className="txt500">Total</span>
                                </div>

                                {/* Discount Amount */}
                                <div className="col-2 d-flex justify-content-center align-items-center">
                                    <span className="txt500">
                                        {discountTotalDisplay
                                            ? isNaN(discountTotal.amount)
                                                ? 0
                                                : discountTotal.amount
                                            : discountAmountDisplay
                                                ? isNaN(discountAmount.amount)
                                                    ? 0
                                                    : discountAmount.amount
                                                : discountTotal.amount
                                        }&nbsp;
                                        {meetingLocation?.Currency === "" ? "Dkk" : meetingLocation?.Currency}
                                    </span>
                                </div>
                            </div>
                        </div>

                        {/*================= Price Total and Add Button =================*/}
                        <div className="row mb-2 mt-2">
                            <div className="col-9">
                            </div>
                            <div className="col-3 text-end addNewRowItem">
                                <button
                                    onClick={() => addRow()}
                                    type="button"
                                    className="btn btn-secondary btn-icon btn-icon-lg"
                                >
                                    <img src={iconAdd} alt="icon-add" />
                                </button>
                            </div>
                        </div>

                        {/*================= Order Details =================*/}
                        <div className="card-content orderDetails">
                            {/*================= Cost Center =================*/}
                            <div className="row costcenter list mt-3">
                                <div className="col-sm-12 col-md-12 col-lg-3 col-xl-xl-3 txt-md mb-2 align-self-center">
                                    <span className="txt500 " >Costcenter<span className="required">*</span></span>
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-9 col-xl-xl-9 txt-md mb-2">
                                    <div className="">
                                        <div>
                                            <div className="inputSec-icon">
                                                <Select
                                                    ref={costCenterRef} openMenuOnFocus={true}
                                                    value={inputDepartment}
                                                    onChange={(value) => setInputDepartment(value)}
                                                    options={allDepartments?.length > 0 ? (
                                                        [
                                                            { value: 0, label: "Select Costcenter" },
                                                            ...allDepartments?.filter((d) => d?.Active)?.find((a) => a?.IsDefault)?.length > 0
                                                                ? allDepartments?.filter((d) => d?.Active)?.find((a) => a?.IsDefault)
                                                                : allDepartments?.filter((d) => d?.Active)?.map((data) => ({ value: data?.Id, label: data?.Name }))
                                                        ]
                                                    ) : (
                                                        [{ value: 0, label: "Select Costcenter" }]
                                                    )}
                                                    className="form-select p-0" placeholder="Select Costcenter"
                                                    styles={{
                                                        control: (baseStyles) => ({
                                                            ...baseStyles,
                                                            border: "none",
                                                            boxShadow: "none",
                                                            padding: "8px 10px",
                                                            borderRadius: 8,
                                                        }),
                                                    }}
                                                    components={{
                                                        IndicatorSeparator: () => null,
                                                        DropdownIndicator: () => null
                                                    }}
                                                />
                                                <span onClick={() => costCenterRef.current?.focus()} className="custom-drop-down-span">
                                                    <img className="custom-drop-down-image" alt="icon-user" src={iconArrow} />
                                                </span>
                                            </div>
                                            <span className="txt-sm txt-gray px-1">
                                                {allDepartments?.find((d) => d?.Id === inputDepartment?.value)?.ManagerName}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/*================= Delivery =================*/}
                            <div className="row list mt-3">
                                <div className="txt-md mb-2 mt-2 col-3">
                                    <span className="txt500">Delivery<span className="required">*</span></span>
                                </div>
                                <div className="txt-md mb-2 mt-2 col-3">
                                    {inputDeliveryMethod}
                                </div>
                                <div className="txt-md mb-2 mt-2 col-3 text-center align-self-center">
                                    <span className="txt500">Pickup Time<span className="required">*</span></span>
                                </div>
                                <div className="txt-md mb-2 col-3">
                                    <ReactDatePicker
                                        type="time"
                                        selected={moment(inputPickupTime, "HH:mm").toDate()}
                                        onChange={(time) => {
                                            setInputPickupTime(time)
                                        }}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={5}
                                        className="form-control"
                                        dateFormat="HH:mm"
                                        timeFormat="HH:mm"
                                        minDate={new Date()}
                                        maxTime={moment().endOf('day').toDate()}
                                        minTime={moment().format("YYYY-MM-DD") === moment(inputDate).format("YYYY-MM-DD") ?
                                            moment().add(30, "m").toDate() :
                                            moment().startOf('day').toDate()}
                                    />
                                </div>
                            </div>

                            <div className="row list mt-3">
                                {/*================= Shared Comment =================*/}
                                <div className="txt-md mb-2 mt-2">
                                    <span className="txt500">Shared Comment</span>
                                    <textarea value={inputSharedComment} onChange={(e) => setInputSharedComment(e.target.value)} className="form-control mt-2" rows={3} placeholder="Shared Comment" />
                                </div>

                                {/*================= Internal Comment =================*/}
                                <div className="txt-md mb-2 mt-2">
                                    <span className="txt500">Internal Comment</span>
                                    <textarea value={inputInternalComment} onChange={(e) => setInputInternalComment(e.target.value)} className="form-control mt-2" rows={3} placeholder="Internal Comment" />
                                </div>

                                {/*================= Delivered By =================*/}
                                {/* <div className="txt-md mt-2">
                                    <span className="txt500">Delivery By</span>
                                    <select value={inputCanteenUser} onChange={(e) => setInputCanteenUser(Number(e.target.value))} className="form-select form-control mt-2" placeholder="Select Deliver by">
                                        <option value={0}>Select Deliver by</option>
                                        {allCanteenUser && allCanteenUser?.length > 0 && (
                                            allCanteenUser?.map((d, i) =>
                                                <option key={i} value={d?.Id}>{d?.Name}</option>
                                            )
                                        )}
                                    </select>
                                </div> */}

                                <div className="txt-md mt-2">
                                    <span className="txt500">Delivery By</span>
                                    <Select
                                        isMulti
                                        value={allCanteenUser
                                            ?.filter((user) => inputCanteenUser.includes(user.Id))
                                            ?.map((user) => ({ value: user.Id, label: user.Name }))}
                                        options={allCanteenUser?.map((d) => ({ value: d?.Id, label: d.Name }))}
                                        className="form-control p-0"
                                        placeholder="Select Deliver by"
                                        onChange={(e) => setInputCanteenUser(e.map((d) => Number(d.value)))}
                                        styles={{
                                            control: (baseStyles) => ({
                                                ...baseStyles,
                                                boxShadow: "none",
                                                borderRadius: 8,
                                                padding: "8px 10px",
                                                fontSize: "18px",
                                                border: "1px solid #e8ecef",
                                                outline: "none"
                                            }),
                                        }}
                                        components={{
                                            IndicatorSeparator: () => null,
                                            DropdownIndicator: () => null,
                                            ClearIndicator: () => null,
                                        }}
                                    />
                                </div>

                                {/* ================= Order By =================*/}
                                <div className="mt-2">
                                    <span className="txt500">Order By<span className="required">*</span></span>
                                    <div className="w-100 custom-search-group-create hover mt-2">
                                        <div
                                            className="d-block"
                                            ref={wrapperRef}
                                            onClick={handleWrapperClick}
                                        >
                                            <ReactSelect
                                                className="basic-single"
                                                placeholder="Search order by"
                                                value={orderByName ? { label: orderByName, value: { Name: orderByName } } : null}
                                                onChange={handleChange}
                                                onInputChange={handleInputChange}
                                                options={colleagueOptions}
                                                formatOptionLabel={formatOptionLabel}
                                                components={{
                                                    IndicatorSeparator: () => null,
                                                    DropdownIndicator: () => null,
                                                    ClearIndicator: () => null,
                                                }}
                                                styles={{
                                                    control: (baseStyles) => ({
                                                        ...baseStyles,
                                                        boxShadow: "none",
                                                        borderRadius: 8,
                                                        padding: "8px 10px",
                                                        fontSize: "18px",
                                                        border: "1px solid #e8ecef",
                                                        outline: "none"
                                                    }),
                                                }}
                                            />
                                        </div>

                                        {(showValidationMessage && userSearch?.length < 3) && (
                                            <div className="search-wrapper" >
                                                <div className="item-card">
                                                    <p className="txt-md">Please enter {3 - userSearch?.length} or more characters</p>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-4">
                        <div className="col-12">
                            <button
                                onClick={() => CreateUpdateOrder()}
                                className="btn btn-primary btn-lg btn-full"
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </>
            )}
        </>
    )
}
